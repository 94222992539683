import { fromEvent } from 'rxjs'

const HoverObj = {
    el: null,
    buttons: null
}

export function ProgressiveHover() {
    const elements = document.querySelectorAll('.js-hover')
    return Array.from(elements).map(createProgressiveHover)
}

function createProgressiveHover(el) {
    const Obj = Object.create(HoverObj)
    init.call(Obj, el)
    return Obj
}

function init(el) {
    if (!el) {
        return
    }

    this.el = el
    this.buttons = el.querySelectorAll('.js-hover-btn')
    this.buttons = Array.from(this.buttons)

    this.el.dataset['hoverLoaded'] = true

    this.buttons.forEach((el) => {
        fromEvent(el, 'mouseenter').subscribe(() => {
            this.el.dataset['hoverActive'] = true
        })

        fromEvent(el, 'mouseleave').subscribe(() => {
            this.el.removeAttribute('data-hover-active')
        })
    })
}
