import { youtubeVideo } from '../../02-tools'

let videos

export function SetupVideos() {
    const videoEls = document.querySelectorAll('.js-video')
    const videoData = Array.from(videoEls)
    videos = videoData.map(setupVideo)
    return videos
}

export function SetupAutoPlayVideos() {
    const videoEls = document.querySelectorAll('.js-autoplay-video')
    const videoData = Array.from(videoEls)
    videos = videoData.map(setupAutoPlayVideo)
    return videos
}

function setupVideo(el) {
    if (!el) {
        return
    }

    const youtubeEl = el.querySelector('[data-youtube-id]')
    const youtube = youtubeVideo(youtubeEl)
    const playBtn = el.querySelector('.js-video-btn')
    const closeBtn = el.querySelector('.js-video-close')

    const close = () => {
        el.classList.remove('is-open')
        youtube.pause()
    }

    if (playBtn) {
        playBtn.addEventListener('click', (event) => {
            if (!youtube.initilised) {
                return
            }

            event.preventDefault();

            el.classList.add('is-open')
            youtube.play()
        })
    }

    if (closeBtn) {
        closeBtn.addEventListener('click', () => {
            close()
        })
    }

    return {
        el,
        playBtn,
        closeBtn,
        player: youtube
    }
}

function setupAutoPlayVideo(el) {
    if (!el) {
        return
    }

    let youtubeEl = el
    if (!el.dataset.youtubeId) {
        youtubeEl = el.querySelector('[data-youtube-id]')
    }

    const youtube = youtubeVideo(youtubeEl, {
        width: window.innerWidth,
        height: el.clientHeight,
        playerVars: {
            loop: true,
            autoplay: true,
            controls: false,
            modestbranding: true,
            mute: true
        }
    })

    return {
        el,
        player: youtube
    }
}
