import { initJump, jump } from './jump'

let pageUrl

export function SmoothScroll() {
    initJump()

    document.addEventListener('click', onClick)

    pageUrl = location.hash ? stripHash(location.href) : location.href
}

function onClick(e) {
    if (!isInPageLink(e.target)) {
        return
    }

    e.stopPropagation()
    e.preventDefault()

    let offset = 0
    let options = {}

    jump(e.target.hash, null, offset, options)
}

function isInPageLink(n) {
    if (!n.tagName) {
        return false
    }
    return n.tagName.toLowerCase() === 'a' && n.hash.length > 0 && stripHash(n.href) === pageUrl
}

function stripHash(url) {
    return url.slice(0, url.lastIndexOf('#'))
}
