const video = {
    videoEl: null,
    youtubeID: null,
    player: null,
    play: null,
    pause: null,
    initilised: false
}

const playerVars = {
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
    widget_referrer: window.location.href
}

let videos = []
let loadedAPi = false

export function youtubeVideo(youtubeEl, options = {}) {
    const Obj = Object.create(video)
    init.call(Obj, youtubeEl, options)
    videos.push(Obj)
    return Obj
}

function loadYoutubeApi() {
    var tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    var firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
}

function init(youtubeEl, options) {
    if (!youtubeEl.dataset || (youtubeEl.dataset && !youtubeEl.dataset.youtubeId)) {
        return
    }

    if (!loadedAPi) {
        loadYoutubeApi()
        loadedAPi = true
    }

    this.videoEl = youtubeEl
    this.youtubeID = youtubeEl.dataset.youtubeId
    this.play = () => {}
    this.pause = () => {}
    this.options = options
    this.onPlaying = options.onPlaying || (() => {})
}

function onYouTubeIframeAPIReady() {
    videos = videos.map((videoObj) => {
        let videoPlayerVars = [{}, playerVars]
        if (videoObj.options.playerVars) {
            videoPlayerVars.push(videoObj.options.playerVars)
        }
        videoPlayerVars = Object.assign.apply(null, videoPlayerVars)

        if (videoPlayerVars.loop) {
            videoPlayerVars.playlist = videoObj.youtubeID
        }

        videoObj.player = new window.YT.Player(videoObj.videoEl, {
            height: 540,
            width: 360,
            videoId: videoObj.youtubeID,
            playerVars: videoPlayerVars,
            events: {
                onReady: (evt) => {
                    videoObj.play = evt.target.playVideo.bind(evt.target)
                    videoObj.pause = evt.target.pauseVideo.bind(evt.target)
                    videoObj.initilised = true
                },
                onStateChange: (evt) => {
                    if (evt.data === window.YT.PlayerState.PLAYING) {
                        videoObj.onPlaying()
                    }
                }
            }
        })
    })
}
window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady
