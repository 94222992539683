export let isScrollingDown = false
let lastScrollY = window.scrollY
let animationFrame = null

window.addEventListener('scroll', updateScrollDirection)

function updateScrollDirection() {
    if (animationFrame) {
        return
    }

    animationFrame = requestAnimationFrame(() => {
        let currentScroll = window.scrollY
        isScrollingDown = currentScroll > lastScrollY
        lastScrollY = currentScroll
        animationFrame = null
    })
}
